const container = document.getElementById('grid-container');
const totalSquares = 300;

// Zahlen 1 bis 26 als Strings
const numbers = Array.from({ length: 26 }, (_, i) => (i + 1).toString());

// Zusätzliche Symbole
const symbols = [
    "α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ",
    "λ", "μ", "ν", "ξ", "ο", "π", "ρ", "σ", "τ", "υ", 
    "φ", "χ", "ψ", "ω", "#", "$", "%", "&"
];

// Füge die Zahlen 1 bis 26 hinzu, danach fülle den Rest mit Symbolen
const allSymbols = [...numbers];

// Fülle die restlichen Quadrate mit zufälligen Symbolen, bis wir 300 Quadrate haben
while (allSymbols.length < totalSquares) {
    const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
    allSymbols.push(randomSymbol);
}

// Funktion zum Mischen der Quadrate
function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// Mische die Reihenfolge der Symbole
shuffle(allSymbols);

// Definiere das Code-Wort
const codeWord = [15, 16, 1, 12]; // OPAL: 15 → 16 → 1 → 12
let currentIndex = 0; // Verfolgt die Position im Code-Wort


function getRandomColor() {
    // HSL-Farben für Opal-ähnliche Töne mit hohem Kontrast
    const hue = Math.random() * 360; // Zufällige Farbtöne
    return `hsl(${hue}, 70%, 50%)`; // Lebendige, kontrastreiche Farben
}

// Erstelle die Quadrate mit dynamischer Farbe
for (let i = 0; i < totalSquares; i++) {
    const square = document.createElement('div');
    square.classList.add('square');
    square.textContent = allSymbols[i];

    // Setze eine passende Farbe
    square.style.setProperty('--text-color', getRandomColor());

    // Klick-Logik
    square.addEventListener('click', () => {
        const clickedValue = parseInt(square.textContent, 10);

        if (clickedValue === codeWord[currentIndex]) {
            // Richtiger Klick
            square.classList.add('visible'); // Zahl dauerhaft sichtbar machen
            currentIndex++;

            // Wenn das gesamte Code-Wort korrekt eingegeben wurde
            if (currentIndex === codeWord.length) {
                window.location.href = "ardw-2025.html"
            }
        } else {
            // Falscher Klick: Fortschritt zurücksetzen
            currentIndex = 0;

            // Optional: Setze die Sichtbarkeit aller Quadrate zurück
            document.querySelectorAll('.square.visible').forEach((sq) => {
                sq.classList.remove('visible');
            });
        }
    });

    container.appendChild(square);
}
